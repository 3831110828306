import { CommodityGroup as MyType } from '@shapeable/copper-connect-types';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
const gql = String.raw;

export const CommodityGroup: EntityConfig<MyType> = {
  name: 'CommodityGroup',
  type: 'taxonomy',
  includeInSearch: true,
  list: {
    hasSearch: true,
    resultsVariant: 'grid',
  },
  autoRoutes: ['create', 'update', 'list', 'details'],
  form: {
    fields: {
      ...entityStandardFormFields,
      sites: { type: 'linkedMultiple', entityTypeName: 'Site' },
      banner: { type: 'banner' },
      description: { type: 'longText' },
      commodities: { type: 'linkedMultiple', entityTypeName: 'Commodity' },
    }
  },
}; 
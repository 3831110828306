import { Barrier as MyType } from '@shapeable/copper-connect-types';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
const gql = String.raw;
import { sortBy } from 'lodash';
export const Barrier: EntityConfig<MyType> = {
  name: 'Barrier',
  label: 'Potential Barrier',
  type: 'taxonomy',
  includeInSearch: true,
  list: {
    resultsVariant: 'list',
  },
  autoRoutes: ['create', 'update', 'list'],
  form: {
    fields: {
      ...entityStandardFormFields,
      description: { type: 'longText' },
    }
  },
}; 
import { InnovationPriority as MyType } from '@shapeable/copper-connect-types'; 
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';

export const InnovationPriority: EntityConfig<MyType> = {
  name: 'InnovationPriority',
  label: 'Innovation Priority',
  type: 'taxonomy',
  includeInSearch: true,
  list: {
    resultsVariant: 'list',
  },
  autoRoutes: ['create', 'update', 'list'],
  form: {
    fields: {
      ...entityStandardFormFields,
      description: { type: 'longText' },
    }
  },
}; 
import { createApplication, entityConfigs, appSectionConfigs } from '@shapeable/core';
import { themes } from '@shapeable/theme';
import { ApplicationLogo, ApplicationLogoInverted } from './components';
import { filter, includes } from 'lodash';
const { Trend, Topic, SubTopic, Organisation, ImpactResultType, HorizonType, Site } = entityConfigs;

// NB: Deposit and DeposityType are not managed by our platform, they are purely imported data
import { Barrier, Catalyst, Commodity, CommodityGroup, Innovation, Technology, InnovationPriority } from './config/entities/';
import { Citation } from '@shapeable/core/dist/config/entities';

const theme = themes.COPPER_THEME;

// pre-patch configs to ensure that derived properties are correctly created 

if (Trend) {
  Trend.extraFields = {
    innovationBanner: { type: 'linked', entityTypeName: 'Banner', label: 'Innovation Banner', description: 'Provide a banner to use when displaying {{INNOVATION}} cards linked to {{TOPICS}} under this {{TREND}}' },
    topicBanner: { type: 'linked', entityTypeName: 'Banner', label: 'Topic Banner', description: 'Provide a banner to use when displaying {{TOPIC}} cards linked to this {{TREND}} when no specific {{BANNER}} is linked to the {{TOPIC}}.' },

    sdgs: { type: 'linkedMultiple', entityTypeName: 'Sdg' },
    citations: { type: 'linkedMultiple', entityTypeName: 'Citation' },
  }
  Trend.label = 'Value Chain Theme';
  Trend.pluralLabel = 'Value Chain Themes';

  Trend.slug = 'theme';
  Trend.pluralSlug = 'themes';
}


if (Topic) {
  Topic.extraFields = {
    sdgs: { type: 'linkedMultiple', entityTypeName: 'Sdg' },
    citations: { type: 'linkedMultiple', entityTypeName: 'Citation' },
  }
}

if (SubTopic) {
  SubTopic.extraFields = {
    sdgs: { type: 'linkedMultiple', entityTypeName: 'Sdg' },
    citations: { type: 'linkedMultiple', entityTypeName: 'Citation' },
  }
}

if (Organisation) {
  Organisation.extraFields = { 
    trends: { type: 'linkedMultiple', entityTypeName: 'Trend' },
    topics: { type: 'linkedMultiple', entityTypeName: 'Topic' },
    subTopics: { type: 'linkedMultiple', entityTypeName: 'SubTopic' },
    technologies: { type: 'linkedMultiple', entityTypeName: 'Technology' },
  }
}


if (ImpactResultType) {
  ImpactResultType.label = 'Impact';
  ImpactResultType.pluralLabel = 'Impacts';
  ImpactResultType.slug = 'impacts';
  ImpactResultType.pluralSlug = 'impacts';
}


if (HorizonType) {
  HorizonType.extraFields = {
    value: { type: 'int' },
  }
}

appSectionConfigs.challengesGoals.label = 'Value Chain';

appSectionConfigs.challengesGoals.children = [
  ...filter(appSectionConfigs.challengesGoals.children, child => includes(['trends', 'topics', 'subTopics', 'sdgs'], child.id)),
  {
    id: 'innovations',
    type: 'list',
    entityTypeName: 'Innovation',
  },
  {
    id: 'commodity-groups',
    type: 'list',
    entityTypeName: 'CommodityGroup',
  },
  {
    id: 'catalysts',
    type: 'list',
    entityTypeName: 'Catalyst',
  },
  {
    id: 'barriers',
    type: 'list',
    entityTypeName: 'Barrier',
  },
  {
    id: 'technologies',
    type: 'list',
    entityTypeName: 'Technology',
  },

];

const myEntityConfigs = { 
  ...entityConfigs,
  Barrier,
  Catalyst,
  Commodity,
  CommodityGroup,
  Innovation,
  InnovationPriority,
  Technology,
};

export const application = createApplication({
  theme,
  entityConfigs: myEntityConfigs,
  options: {
    systemsMapping: 'full',
  },
  components: {
    /* @ts-ignore */
    Logo: ApplicationLogo,
    /* @ts-ignore */
    LogoInverted: ApplicationLogoInverted,
  },
});

/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;

delete entities.Challenge;
delete entities.KeyIssue;
delete entities.ImpactStory;


